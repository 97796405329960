import { useState } from 'react';


import "./Homepage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Alert,
    Button
} from 'react-bootstrap';
const Homepage = () => {
    const data = {
        title: "Welcome",
        desc: "Welcome to the text and description"
    }
    const myNumber = [6,4,8,12];
    const myfunc = () =>{
        return "My function value";
    };
    const CheckAlert = () => {
        alert("Testing alert Warning!");
    }   
    const [show, setShow] = useState(false);

    const design = (
        <>
            <div id="content">
                <div id="wrapper">
                    <h2>This site is currently undergoing scheduled maintenance.</h2>
                    <p>Please try back soon!</p>
                </div>
            </div>
        </>
    );
    return design;
}
export default Homepage;
