const Contactpage = () => {
    const data = {
        title: "Contact Us",
        desc: "Welcome to the Contact Us Page"
    }
    const design = (
        <>
            <h1>{data.title}</h1>
            <p>{data.desc} </p>
        </>
    );
    return design
}
export default Contactpage;