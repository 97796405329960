import "./Navbar.css";
import mainlogo2 from './logo192.png';
import { Link } from "react-router-dom";
import Menu from './Menu.json';

const Navbar = () => {
    const design = (
        <>
        <header>
            <ul>
                <li><Link to="/"><img src={mainlogo2} width="50px" /></Link></li>
                    {
                        Menu.map( (items) => {
                            return  <li>
                                    <Link to={items.url}>{items.label}</Link>
                                    </li>
                        } )
                    }
            </ul>
        </header>
        </>    
    );
    return design;
}
export default Navbar;