import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footerbar from './components/Footerbar/Footerbar';
import Homepage from './components/Homepage/Homepage';
import Aboutpage from './components/About/About';
import Contactpage from './components/Contact/Contact';

import { 
  BrowserRouter,
  Routes,
  Route
  } from 'react-router-dom';
   
const first = () => {
  const first_inner_block = (
      <BrowserRouter>
        
        <div className="page">
          <div className='container'>
            <Routes>
              <Route exact path="/" element={<Homepage />} > </Route>
              <Route exact path="/about"  element={<Aboutpage />}  > </Route>
              <Route exact path="/gallery" element ="Gallery"> </Route>
              <Route exact path="/projects" element ="Projects" > </Route>
              <Route exact path="/contact"  element ={<Contactpage />} > </Route>
            </Routes>
          </div>
          
        </div>
        
      </BrowserRouter>
  );
  return first_inner_block;
}
export default first;