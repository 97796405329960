const Aboutpage = () => {
    const data = {
        title: "About Us Page",
        desc: "Welcome to the About Us Page"
    }
    const design = (
        <>
            <h1>{data.title}</h1>
            <p>{data.desc} </p>
            <p>{JSON.stringify(data)}</p>
        </>
    );
    return design
}
export default Aboutpage;